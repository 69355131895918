import React, { Component } from 'react';

function Registry() {
   return (
      <section id="registry">
            <div className="registry-content-container column">
               <h1>Registry</h1>
               <h2>Thank you for sharing this special occasion with us!!</h2>
               <h2>If you would like to give us a gift</h2>
               <h2>Venmo Handle</h2>
               <h2>@ben234</h2>
            </div>
      </section>
   );
  
}

export default Registry;
