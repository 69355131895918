import React, { Component } from 'react';

function Footer(){
 
   return (
      <footer>
         <div className="row ">
            <div className="twelve columns">
               <ul className="social-links">
           
               </ul>
            </div>
         <div  id="go-top"><a className="smoothscroll" title="Back to Top" href="#home"><i className="icon-up-open footer-icon"></i></a></div>
         </div>
      </footer>
   );
}

export default Footer;